import { AdapterType } from "@enzymefinance/environment";
import { Portfolio } from "@enzymefinance/sdk";
import { Assertion } from "@enzymefinance/sdk/Utils";
import { Textarea } from "@enzymefinance/ui";
import { assertNever } from "@enzymefinance/utils";
import { safeStringifyJSON } from "@enzymefinance/utils";
import { type Address, type Hex, decodeAbiParameters, parseAbiParameters } from "viem";
import { aaveLend, aaveRedeem } from "./aaveV2";
import { aaveV3Lend, aaveV3Redeem } from "./aaveV3";
import {
  balancerClaimRewards,
  balancerDeposit,
  balancerDepositAndStake,
  balancerRedeem,
  balancerStake,
  balancerUnstake,
  balancerUnstakeAndRedeem,
} from "./balancer";
import { compoundV2Lend, compoundV2Redeem } from "./compoundV2";
import { compoundV3ClaimRewards, compoundV3Lend, compoundV3Redeem } from "./compoundV3";
import {
  curveClaimRewards,
  curveDeposit,
  curveDepositAndStake,
  curveRedeem,
  curveStake,
  curveTakeOrder,
  curveUnstake,
  curveUnstakeAndRedeem,
} from "./curve";
import { erc4626Lend, erc4626Redeem } from "./erc4626";
import { oneInch5 } from "./oneInchV5";
import { paraSwapV5 } from "./paraSwapV5";
import { paraSwapV6SwapExactAmountIn, paraSwapV6SwapExactAmountOut } from "./paraSwapV6";
import {
  addLiquidity,
  buyPrincipalToken,
  removeLiquidityToPtAndUnderlying,
  removeLiquidityToUnderlying,
  sellPrincipalToken,
} from "./pendleV2";
import { threeOneThirdTakeOrder } from "./threeOneThird";
import type { GetIntegrationHandlerDependencies, IntegrationHandler } from "./types";
import { uniswapV2Lend, uniswapV2Redeem, uniswapV2TakeOrder } from "./uniswapV2";
import { uniswapV3TakeOrder } from "./uniswapV3";
import { yearnV2Lend, yearnV2Redeem } from "./yearn";
import { zeroExV2TakeOrder } from "./zeroExV2";
import { zeroExV4TakeOrder } from "./zeroExV4";

interface CallOnIntegrationArgs {
  adapterAddress: Address;
  selector: Hex;
  encodedCallArgs: Hex;
}

export function decodeCallOnIntegrationArgs(callOnExtensionArgs: Hex): CallOnIntegrationArgs {
  const [adapterAddress, selector, encodedCallArgs] = decodeAbiParameters(
    parseAbiParameters("address, bytes4, bytes"),
    callOnExtensionArgs,
  );

  return {
    adapterAddress,
    encodedCallArgs,
    selector,
  };
}

export function getIntegrationHandler(
  { adapterAddress, selector, encodedCallArgs }: CallOnIntegrationArgs,
  { getAdapterByAddress }: GetIntegrationHandlerDependencies,
): IntegrationHandler {
  const adapter = getAdapterByAddress(adapterAddress);

  const adapterType = adapter?.type as AdapterType | undefined;

  switch (adapterType) {
    case AdapterType.AAVE_V2: {
      switch (selector) {
        case Portfolio.IntegrationAdapterSelector.Lend:
          return aaveLend;
        case Portfolio.IntegrationAdapterSelector.Redeem:
          return aaveRedeem;
      }

      throw new Error(`Unknown selector: ${selector}`);
    }

    case AdapterType.ZERO_LEND_LRT_BTC_AAVE_V3:
    case AdapterType.ZERO_LEND_RWA_STABLECOINS_AAVE_V3:
    case AdapterType.AAVE_V3: {
      switch (selector) {
        case Portfolio.IntegrationAdapterSelector.Lend:
          return aaveV3Lend(adapterType);
        case Portfolio.IntegrationAdapterSelector.Redeem:
          return aaveV3Redeem(adapterType);
      }

      throw new Error(`Unknown selector: ${selector}`);
    }

    case AdapterType.COMPOUND_V2: {
      switch (selector) {
        case Portfolio.IntegrationAdapterSelector.Lend:
          return compoundV2Lend;
        case Portfolio.IntegrationAdapterSelector.Redeem:
          return compoundV2Redeem;
      }

      throw new Error(`Unknown selector: ${selector}`);
    }

    case AdapterType.COMPOUND_V3: {
      switch (selector) {
        case Portfolio.IntegrationAdapterSelector.Lend:
          return compoundV3Lend;
        case Portfolio.IntegrationAdapterSelector.Redeem:
          return compoundV3Redeem;
        case Portfolio.IntegrationAdapterSelector.ClaimRewards:
          return compoundV3ClaimRewards;
      }

      throw new Error(`Unknown selector: ${selector}`);
    }

    case AdapterType.CURVE_EXCHANGE: {
      if (selector === Portfolio.IntegrationAdapterSelector.TakeOrder) {
        return curveTakeOrder;
      }

      throw new Error(`Unknown selector: ${selector}`);
    }

    case AdapterType.BALANCER_V2: {
      switch (selector) {
        case Portfolio.IntegrationAdapterSelector.Lend:
          return balancerDeposit;
        case Portfolio.IntegrationAdapterSelector.LendAndStake:
          return balancerDepositAndStake;
        case Portfolio.IntegrationAdapterSelector.Stake:
          return balancerStake;
        case Portfolio.IntegrationAdapterSelector.Unstake:
          return balancerUnstake;
        case Portfolio.IntegrationAdapterSelector.Redeem:
          return balancerRedeem;
        case Portfolio.IntegrationAdapterSelector.UnstakeAndRedeem:
          return balancerUnstakeAndRedeem;
        case Portfolio.IntegrationAdapterSelector.ClaimRewards:
          return balancerClaimRewards;
      }

      throw new Error(`Unknown selector: ${selector}`);
    }

    case AdapterType.CURVE_LIQUIDITY:
    case AdapterType.CURVE_LIQUIDITY_AAVE:
    case AdapterType.CURVE_LIQUIDITY_EURS:
    case AdapterType.CURVE_LIQUIDITY_SETH:

    case AdapterType.CURVE_LIQUIDITY_STETH: {
      switch (selector) {
        case Portfolio.IntegrationAdapterSelector.ClaimRewards:
          return curveClaimRewards;
        case Portfolio.IntegrationAdapterSelector.Lend:
          return curveDeposit;
        case Portfolio.IntegrationAdapterSelector.LendAndStake:
          return curveDepositAndStake;
        case Portfolio.IntegrationAdapterSelector.Stake:
          return curveStake;
        case Portfolio.IntegrationAdapterSelector.Unstake:
          return curveUnstake;
        case Portfolio.IntegrationAdapterSelector.UnstakeAndRedeem:
          return curveUnstakeAndRedeem;
        case Portfolio.IntegrationAdapterSelector.Redeem:
          return curveRedeem;
      }

      throw new Error(`Unknown selector: ${selector}`);
    }

    case AdapterType.ERC_4626: {
      switch (selector) {
        case Portfolio.IntegrationAdapterSelector.Lend:
          return erc4626Lend;
        case Portfolio.IntegrationAdapterSelector.Redeem:
          return erc4626Redeem;
      }

      throw new Error(`Unknown selector: ${selector}`);
    }

    case AdapterType.PARASWAP_V5: {
      if (selector === Portfolio.IntegrationAdapterSelector.TakeOrder) {
        return paraSwapV5;
      }

      throw new Error(`Unknown selector: ${selector}`);
    }

    case AdapterType.PARASWAP_V6: {
      if (selector === Portfolio.IntegrationAdapterSelector.Action) {
        const { actionId } = Portfolio.Integrations.ParaSwapV6.decodeAdapterAction(encodedCallArgs);

        const typedActionId = actionId as Portfolio.Integrations.ParaSwapV6.AdapterAction;
        switch (typedActionId) {
          case Portfolio.Integrations.ParaSwapV6.AdapterAction.SwapExactAmountOut:
            return paraSwapV6SwapExactAmountOut;
          case Portfolio.Integrations.ParaSwapV6.AdapterAction.SwapExactAmountIn:
            return paraSwapV6SwapExactAmountIn;
          default:
            Assertion.never(typedActionId, `Unknown actionId: ${typedActionId}`);
        }
      }

      throw new Error(`Unknown selector: ${selector}`);
    }

    case AdapterType.ONE_INCH_V5: {
      if (selector === Portfolio.IntegrationAdapterSelector.TakeOrder) {
        return oneInch5;
      }

      throw new Error(`Unknown selector: ${selector}`);
    }

    case AdapterType.UNISWAP_V2:
    case AdapterType.UNISWAP_V2_LIQUIDITY: {
      switch (selector) {
        case Portfolio.IntegrationAdapterSelector.TakeOrder:
          return uniswapV2TakeOrder;
        case Portfolio.IntegrationAdapterSelector.Lend:
          return uniswapV2Lend;
        case Portfolio.IntegrationAdapterSelector.Redeem:
          return uniswapV2Redeem;
      }

      throw new Error(`Unknown selector: ${selector}`);
    }

    case AdapterType.UNISWAP_V3: {
      return uniswapV3TakeOrder;
    }

    case AdapterType.YEARN_VAULT_V2: {
      switch (selector) {
        case Portfolio.IntegrationAdapterSelector.Lend:
          return yearnV2Lend;
        case Portfolio.IntegrationAdapterSelector.Redeem:
          return yearnV2Redeem;
      }

      throw new Error(`Unknown selector: ${selector}`);
    }

    case AdapterType.ZERO_EX_V2: {
      return zeroExV2TakeOrder;
    }

    case AdapterType.ZERO_EX_V4:
    case AdapterType.ZERO_EX_V4_PMM_KYC: {
      return zeroExV4TakeOrder;
    }

    case AdapterType.THREE_ONE_THIRD: {
      if (selector === Portfolio.IntegrationAdapterSelector.TakeOrder) {
        return threeOneThirdTakeOrder;
      }

      throw new Error(`Unknown selector: ${selector}`);
    }

    case AdapterType.PENDLE_V2: {
      if (Portfolio.IntegrationAdapterSelector.Action) {
        const { actionId } = Portfolio.Integrations.PendleV2.decodeAdapterAction(encodedCallArgs);

        const typedActionId = actionId as Portfolio.Integrations.PendleV2.AdapterAction;
        switch (typedActionId) {
          case Portfolio.Integrations.PendleV2.AdapterAction.BuyPrincipalToken:
            return buyPrincipalToken;
          case Portfolio.Integrations.PendleV2.AdapterAction.SellPrincipalToken:
            return sellPrincipalToken;
          case Portfolio.Integrations.PendleV2.AdapterAction.AddLiquidity:
            return addLiquidity;
          case Portfolio.Integrations.PendleV2.AdapterAction.RemoveLiquidityToUnderlying:
            return removeLiquidityToUnderlying;
          case Portfolio.Integrations.PendleV2.AdapterAction.RemoveLiquidityToPtAndUnderlying:
            return removeLiquidityToPtAndUnderlying;
          default:
            Assertion.never(typedActionId, `Unknown actionId: ${typedActionId}`);
        }
      }

      throw new Error(`Unknown selector: ${selector}`);
    }
    case AdapterType.AURA:
    case AdapterType.CONVEX:
    case AdapterType.ALPHA_HOMORA_V1:
    case AdapterType.KYBER_NETWORK:
    case AdapterType.OLYMPUS_V2:
    case AdapterType.PARASWAP_V4:
    case AdapterType.POOLTOGETHER_V4:
    case AdapterType.SWELL_STAKING:
    case AdapterType.TRACKED_ASSETS:
    case AdapterType.IDLE:
    case AdapterType.SYNTHETIX:
    case AdapterType.ENZYME_V4_VAULT:
    case AdapterType.UNKNOWN:
    case undefined:
      return getDefaultIntegrationHandler(adapterAddress, selector);
    default:
      assertNever(adapterType, "Unknown adapter type");
  }
}

function getDefaultIntegrationHandler(
  adapterAddress: Address,
  selector: Hex,
): IntegrationHandler<CallOnIntegrationArgs> {
  return {
    Description({ args }) {
      return (
        <Textarea
          label="Raw args"
          id="default-integration-handler"
          readOnly={true}
          rows={8}
          value={safeStringifyJSON(args, 2)}
        />
      );
    },
    Label() {
      return <>Interact with Integrated Protocol</>;
    },
    decodeIntegrationArgs: (encodedCallArgs) => ({ adapterAddress, encodedCallArgs, selector }),
  };
}
